import marileneColoredPicture from '../../assets/marilene-picture-color.png';
import marileneBlackAndWhitePicture from '../../assets/marilene-picture-black-and-white.webp';
import studioHnaLogo from '../../assets/studio-hna-logo.webp';
import naturokosLogo from '../../assets/naturokos-logo.webp';
import franzVBLogo from '../../assets/franz-von-bueren-logo.webp';
import { servicesElementsData } from '../../constants/showcase-website.constants';
import Button from '../atoms/button/button';
import AnimatedLogo from '../molecules/animated-logo';
import Card from '../molecules/card';

import mintfarbenLogoDarkGrey from '../../assets/mintfarben.studio-logo-dark-grey.png';
import mintfarbenLogoMintgreen from '../../assets/mintfarben.studio-logo-mintgreen.png';
import mintfarbenLogoYellow from '../../assets/mintfarben.studio-logo-yellow.png';

interface HomepageOrganismProps {
  calendlyTag: string | JSX.Element;
  mailContactContent: string | JSX.Element;
  hoveredCardId: number | null;
  handleMouseEnter: (value: number) => void;
  handleMouseLeave: () => void;
  handleButtonMouseEnter: (value: number) => void;
  handleButtonMouseLeave: () => void;
}

const HomepageOrganism = (props: HomepageOrganismProps) => {
  const {
    calendlyTag,
    mailContactContent,
    hoveredCardId,
    handleMouseEnter,
    handleMouseLeave,
    handleButtonMouseEnter,
    handleButtonMouseLeave,
  } = props;

  const picturesData = [
    {
      src: marileneBlackAndWhitePicture,
      alt: 'Photo de Marilène Khieu en noir et blanc',
      title: 'Photo de Marilène Khieu',
      className: 'marilene-black-white-picture',
    },
  ];

  const logosData = [
    {
      src: mintfarbenLogoYellow,
      alt: 'mintfarben. logo yellow',
      title: "mintfarben. Studio's logo",
      className: 'mintfarben-logo',
    },
    {
      src: mintfarbenLogoMintgreen,
      alt: 'mintfarben. logo mintgreen',
      title: "mintfarben. Studio's logo",
      className: 'mintfarben-logo',
    },
    {
      src: mintfarbenLogoDarkGrey,
      alt: 'mintfarben. logo dark grey',
      title: "mintfarben. Studio's logo",
      className: 'mintfarben-logo',
    },
  ];

  return (
    <main id="homepage-main">
      <section id="studio" className="section-standard-padding-left-right">
        <div className="picture-text-container">
          <div className="animation-container">
            <AnimatedLogo imagesData={picturesData} />
          </div>

          <div className="home-content">
            <p>
              <span className="mintgreen-underline">
                <b>Gagnez de nouveaux clients</b>
              </span>{' '}
              avec{' '}
              <span className="mintgreen-underline">
                <b>un site bien référencé sur Google</b>
              </span>{' '}
              et{' '}
              <span className="mintgreen-underline">
                <b>un copywriting impactant</b>
              </span>{' '}
              grâce aux stratégies utilisées par{' '}
              <b>les meilleures entreprises</b>.
            </p>

            <div className="button-calendly-container">
              <Button
                className="plain-button calendly-button"
                content={calendlyTag}
                onClick={() => {}}
              />
            </div>
          </div>
        </div>
      </section>

      <section id="services" className="section-standard-padding-left-right">
        <div>
          <h2>NOTRE EXPERTISE</h2>

          <div className="cards-services-container">
            {servicesElementsData.map((service) => {
              return (
                <div key={service.id} className="single-card-container">
                  <Card
                    content={service}
                    onMouseEnter={() => handleMouseEnter(service.id)}
                    onMouseLeave={handleMouseLeave}
                    isHovered={hoveredCardId === service.id}
                  />

                  {hoveredCardId === service.id && (
                    <div
                      className="hidden-button"
                      onMouseEnter={() => handleButtonMouseEnter(service.id)}
                      onMouseLeave={handleButtonMouseLeave}
                    >
                      <Button
                        className={`plain-button calendly-button ${
                          hoveredCardId ? 'visible' : ''
                        }`}
                        content={calendlyTag}
                        onClick={() => {}}
                      />
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        </div>
      </section>

      <section id="clients" className="section-standard-padding-left-right">
        <div>
          <h2>CE QUE DISENT MES CLIENTS</h2>
          <div className="customers-reviews-container">
            <div className="card-customer-review-container">
              <div>
                <a href="https://studiohna.com" target="_blank">
                  <div
                    id="customer-1-background-image"
                    className="customer-review-image"
                  />
                </a>
              </div>

              <div className="customer-review-description">
                <a href="https://studiohna.com" target="_blank">
                  <img
                    className="clients-logo"
                    src={studioHnaLogo}
                    alt="Logo Studio HnA"
                    title=""
                  />
                </a>
                <div>
                  <h3>Audit SEO & du site Internet de Studio HnA</h3>
                  <cite>
                    "J'ai fait appel à Mintfarben Studio pour un diagnostique de
                    mon site internet. Le rapport était très complet, détaillé
                    et pertinent. La discussion était enrichissante et
                    l'approche de Mintfarben était vraiment personnalisée. Merci
                    pour cet accompagnement de qualité !"
                    <br />- Nicolas Ruiz, architecte -
                  </cite>
                </div>
              </div>
            </div>

            <div className="card-customer-review-container">
              <div>
                <a href="https://www.naturokos.com/" target="_blank">
                  <div
                    id="customer-2-background-image"
                    className="customer-review-image"
                  />
                </a>
              </div>

              <div className="customer-review-description">
                <a href="https://www.naturokos.com/" target="_blank">
                  <img
                    className="clients-logo"
                    src={naturokosLogo}
                    alt="Custom Lobby Logo"
                    title=""
                  />
                </a>
                <div>
                  <h3>Audit SEO & du site Internet de NaturoKos</h3>

                  <cite>
                    "Ayant peu de connaissances en informatique et en marketing,
                    j'avais besoin d'infos claires et faciles à mettre en place.
                    Marilène a su m'expliquer simplement comment l'améliorer et
                    comment obtenir une meilleure visibilité."
                    <br />- Anne Lagière, naturopathe -
                  </cite>
                </div>
              </div>
            </div>

            <div className="card-customer-review-container margin-bottom-card-customer">
              <div>
                <a href="https://franzvonbueren.com" target="_blank">
                  <div
                    id="customer-3-background-image"
                    className="customer-review-image"
                  />
                </a>
              </div>

              <div className="customer-review-description">
                <a href="https://franzvonbueren.com" target="_blank">
                  <img
                    className="clients-logo"
                    src={franzVBLogo}
                    alt="Logo Franz von Büren"
                    title=""
                  />
                </a>
                <div>
                  <h3>Création de site Internet pour Franz von Büren</h3>
                  <cite>{/* <br />- Franz von Büren, vidéaste - */}</cite>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="mintfarben" className="section-standard-padding-left-right">
        <div>
          <h2>UN ACCOMPAGNEMENT PERSONNALISÉ</h2>

          <div id="mintfarben-container">
            <img
              className="marilene-color-picture"
              src={marileneColoredPicture}
              alt="Marilene's picture"
              title=""
            />
            <div className="mintfarben-content">
              <p>
                <b>mintfarben. Studio</b> est une <b>agence de webmarketing</b>{' '}
                qui <span className="mintgreen-underline">vous accompagne</span>{' '}
                et{' '}
                <span className="mintgreen-underline">
                  vous conseille à chaque étape
                </span>{' '}
                de la création de votre stratégie de marketing digital et la
                conception de votre site Internet.
              </p>

              <p>
                Derrière <b>mintfarben. Studio</b> se cache{' '}
                <b>Marilène Khieu</b> :
              </p>

              <cite>
                "Hello, je suis Marilène =).<br></br> À l’écoute, sensible,
                dotée d'un fort esprit d'équipe et avec une profonde envie
                d’aider et de conseiller, je suis résolument tournée vers
                l'humain.
                <br></br>Avec plus de 10 ans d'expérience en entreprise et en
                start-up à Paris et à Berlin, j'ai acquis des compétences clés
                aux postes de Sales, Customer Success Manager et en
                développement web.<br></br>Mon objectif ? Vous aider à attirer
                durablement de nouveaux clients et à booster votre visibilité en
                ligne."
              </cite>
            </div>
          </div>
        </div>
      </section>

      <section id="contact" className="section-standard-padding-left-right">
        <div className="picture-text-container">
          <div className="animation-container">
            <AnimatedLogo imagesData={logosData} />
          </div>

          <div className="contact-content">
            <div>
              <p>
                Vous aimeriez optimiser votre visibilité en ligne et{' '}
                <b>vous avez besoin de conseils</b> ?
              </p>
              <p>
                Vous aimeriez développer votre chiffre d’affaires mais{' '}
                <b>vous ne savez pas comment faire</b> ?
              </p>
              <p>
                <span className="mintgreen-underline">
                  Parlons-en dès maintenant
                </span>{' '}
                =).
              </p>
            </div>

            <div className="button-calendly-container button-contact-container">
              <Button
                className="plain-button calendly-button"
                content={calendlyTag}
                onClick={() => {}}
              />
            </div>

            <div className="contact-via-mail-container">
              <Button
                className="contact-via-mail"
                content={mailContactContent}
                onClick={() => {}}
              />
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default HomepageOrganism;
